import axios from 'axios';
import { _AUTH } from 'constants/_auth';

const axiosClient = axios.create({
	baseURL: 'https://taydo.taydocantho.com',
	// baseURL: 'https://quanlysinhvien.truongtaydocantho.com',
	headers: {
		'content-type': 'application/json; charset=utf8',
		Accept: 'application/json',
	},
});

axiosClient.interceptors.request.use(async (config) => {
	const token = localStorage.getItem(_AUTH.TOKEN_NAME);
	if (token) {
		config.headers = {
			Authorization: `Bearer ${localStorage.getItem(_AUTH.TOKEN_NAME)}`,
		};
	}
	return config;
});

axiosClient.interceptors.response.use((response) => {
	if (response && response.data) {
		return response.data;
	}
	return response;
});

export default axiosClient;
